import React from "react";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as AppstoreLogo } from "../assets/img/download/appstore.svg";
import { ReactComponent as PlaystoreLogo } from "../assets/img/download/playstore.svg";
import { ReactComponent as WingBookDark } from "../assets/img/logo/winged-book-dark.svg";
import { ReactComponent as WingBookWhite } from "../assets/img/logo/winged-book-white.svg";

import { useLocation } from "react-router-dom";

import { TRY_APP_URL } from "../helper/index";

export const About = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const appSelected = searchParams.get("app") || "pdg";

  return (
    <React.Fragment>
      <div
        id="about-images"
        className={appSelected === "sjt" ? "about-images-sjt" : ""}
      >
        {/* <Parallax speed={-10}> */}
        {appSelected !== "sjt" && (
          <div className="container about-images-container">
            <div className="col-lg-12 col-md-12 col-xs-12 row about-rank-study">
              <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 pl-0">
                <h2>Select the Rank{!isMobile && <br></br>} you need to ace</h2>
                {/* <p>
                Start by selecting the rank you need to get ready for. We
                automatically set up your app experience by matching your
                selection to the latest Professional Fitness Exam (PFE) testable
                content.
              </p> */}
                <p>
                  We set up your app experience by matching your rank selection
                  to the latest Professional Fitness Exam (PFE) testable
                  content.
                </p>
                <p>
                  Ace PDG is updated when the Air Force releases a new guide or
                  changes AFH-1 or AFH 36-2647.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-2 select-rank-img">
                <img
                  src={"img/screenshots/select-rank-1.png"}
                  className="rank-1"
                />
                <img
                  src={"img/screenshots/select-rank-2.png"}
                  className="rank-2"
                />
              </div>
            </div>
          </div>
        )}
        {/* </Parallax> */}
        {/* STUDY MODE ACE SJT */}
        {appSelected === "sjt" ? (
          <>
            {isMobile ? (
              <div className="container about-images-container no-overflow mb-4">
                <div className="col-lg-12 col-md-12 col-xs-12 row about-study-mode">
                  <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-1 study-mode-container">
                    <img
                      src={"img/screenshots/study-mode-sjt.png"}
                      className="study-mode-img"
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 pl-0 study-text-container">
                    <h2>Study Mode</h2>
                    <p>
                      Study with over 300 unique hypothetical scenarios. We
                      provide explanations for why responses are the most and
                      least effective so you can master the SJT way of thinking
                      and score a perfect 25/25 on the SJT section of the Air
                      Force PFE!
                    </p>

                    {!isMobile && (
                      <a
                        href={TRY_APP_URL}
                        target="_blank"
                        className="btn btn-custom btn-custom-light study-btn mt-4"
                      >
                        Give it a try now!
                        <i class="fa  fa-arrow-right btn-icon-right"></i>
                      </a>
                    )}
                  </div>
                  {isMobile && (
                    <a
                      href={TRY_APP_URL}
                      target="_blank"
                      className="btn btn-custom btn-custom-white btn-leaderboard mt-4"
                    >
                      Give this a try now!
                      <i class="fa  fa-arrow-right btn-icon-right"></i>
                    </a>
                  )}
                </div>
              </div>
            ) : (
              <div className="container about-images-container no-overflow mb-4">
                <div className="col-lg-12 col-md-12 col-xs-12 row about-study-mode">
                  <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 pl-0 study-text-container">
                    <h2>Study Mode</h2>
                    <p>
                      Study with over 300 unique hypothetical scenarios. We
                      provide explanations for why responses are the most and
                      least effective so you can master the SJT way of thinking
                      and score a perfect 25/25 on the SJT section of the Air
                      Force PFE!
                    </p>

                    {!isMobile && (
                      <a
                        href={TRY_APP_URL}
                        target="_blank"
                        className="btn btn-custom btn-custom-light study-btn mt-4"
                      >
                        Give it a try now!
                        <i class="fa  fa-arrow-right btn-icon-right"></i>
                      </a>
                    )}
                  </div>
                  {isMobile && (
                    <a
                      href={TRY_APP_URL}
                      target="_blank"
                      className="btn btn-custom btn-custom-white btn-leaderboard mt-4"
                    >
                      Give this a try now!
                      <i class="fa  fa-arrow-right btn-icon-right"></i>
                    </a>
                  )}
                  <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-1 study-mode-container">
                    <img
                      src={"img/screenshots/study-mode-sjt.png"}
                      className="study-mode-img"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          // ACE PDG
          <div className="container about-images-container no-overflow mb-4">
            <div className="col-lg-12 col-md-12 col-xs-12 row about-study-mode">
              <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-1 study-mode-container">
                <img
                  src={"img/screenshots/study-mode.png"}
                  className="study-mode-img"
                />
              </div>
              <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 pl-0 study-text-container">
                <h2>Study Mode</h2>
                <p>
                  Study with flashcards or read testable sections of AFH1. Flash
                  cards include the 24 Foundational Principles in AFH 36-2647
                  and prepare you for the Situational Judgment Test (SJT) part
                  of the PFE.
                </p>
                <p>
                  On the road or exercising? Try our audio feature in Handbook
                  mode.
                </p>

                {!isMobile && (
                  <a
                    href={TRY_APP_URL}
                    target="_blank"
                    className="btn btn-custom btn-custom-light study-btn mt-4"
                  >
                    Give it a try now!
                    <i class="fa  fa-arrow-right btn-icon-right"></i>
                  </a>
                )}
              </div>
              {isMobile && (
                <a
                  href={TRY_APP_URL}
                  target="_blank"
                  className="btn btn-custom btn-custom-white btn-leaderboard mt-4"
                >
                  Give it a try now!
                  <i class="fa  fa-arrow-right btn-icon-right"></i>
                </a>
              )}
            </div>
          </div>
        )}
        {appSelected === "sjt" ? (
          <>
            {isMobile ? (
              <div
                id="quiz-mode"
                className="container about-images-container no-overflow mt-4"
              >
                <div className="col-lg-12 col-md-12 col-xs-12 row about-quiz-mode">
                  <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 quiz-text-container">
                    <h2>Quiz Mode</h2>
                    <p>
                      Quiz yourself once you’ve reviewed the flash cards. You’ll
                      get immediate feedback to prepare you for the SJT.
                    </p>
                    <p>
                      Want to ensure you’ll ace the SJT? Study every section and
                      answer all 600+ quiz questions!
                    </p>
                    {!isMobile && (
                      <a
                        href={TRY_APP_URL}
                        target="_blank"
                        className="btn btn-custom btn-custom-light quiz-btn mt-4"
                      >
                        Give this a try now!
                        <i class="fa  fa-arrow-right btn-icon-right"></i>
                      </a>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-1 quiz-mode-container">
                    <img
                      src={"img/screenshots/quiz-mode-sjt.png"}
                      className="quiz-mode-img"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                id="quiz-mode"
                className="container about-images-container no-overflow mt-4"
              >
                <div className="col-lg-12 col-md-12 col-xs-12 row about-quiz-mode">
                  <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-1 quiz-mode-container">
                    <img
                      src={"img/screenshots/quiz-mode-sjt.png"}
                      className="quiz-mode-img"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 quiz-text-container">
                    <h2>Quiz Mode</h2>
                    <p>
                      Quiz yourself once you’ve reviewed the flash cards. You’ll
                      get immediate feedback to prepare you for the SJT.
                    </p>
                    <p>
                      Want to ensure you’ll ace the SJT? Study every section and
                      answer all 600+ quiz questions!
                    </p>
                    {!isMobile && (
                      <a
                        href={TRY_APP_URL}
                        target="_blank"
                        className="btn btn-custom btn-custom-light quiz-btn mt-4"
                      >
                        Give this a try now!
                        <i class="fa  fa-arrow-right btn-icon-right"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div
            id="quiz-mode"
            className="container about-images-container no-overflow mt-4"
          >
            <div className="col-lg-12 col-md-12 col-xs-12 row about-quiz-mode">
              <div className="col-lg-4 col-md-4 col-md-offset-1 col-xs-6 quiz-text-container">
                <h2>Quiz Mode</h2>
                <p>
                  Quiz yourself any time you finish one of the study sections.
                  Our section quizzes cover every testable topic, including the
                  SJT.
                </p>
                <p>
                  Want to ensure you’ll ace the PFE? Study every section and
                  answer all 4,000+ quiz questions!
                </p>
                {!isMobile && (
                  <a
                    href={TRY_APP_URL}
                    target="_blank"
                    className="btn btn-custom btn-custom-light quiz-btn mt-4"
                  >
                    Give it a try now!
                    <i class="fa  fa-arrow-right btn-icon-right"></i>
                  </a>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-xs-6 col-md-offset-1 quiz-mode-container">
                <img
                  src={"img/screenshots/quiz-mode.png"}
                  className="quiz-mode-img"
                />
              </div>
            </div>
          </div>
        )}
        {/* MOCK EXAM MODE & PDG LEADERBOARD */}
        {appSelected === "sjt" ? (
          <div className="container-fluid about-images-container">
            <div className="col-lg-6 col-md-6 col-xs-12 row about-mock-exam float-left">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <WingBookDark width={82} />
                <h2>
                  Mock Exam<br></br>Mode
                </h2>
                <p>
                  Think you’ll ace it? Take the closest to actual Promotion
                  Fitness Exam SJT! 20 questions based on the 24 Foundational
                  Principles outlined in AFH 36-2647. Each question is 1.25
                  points, totaling 25.
                </p>
                <p>
                  Take the mock exam as often as you need to feel ready. Each
                  exam features new scenarios!
                </p>
                <a
                  href={TRY_APP_URL}
                  target="_blank"
                  className={`btn btn-custom ${
                    isMobile ? "btn-custom-white" : "btn-custom-light"
                  } btn-mock-exam mt-4`}
                >
                  Give it a try now!
                  <i class="fa  fa-arrow-right btn-icon-right"></i>
                </a>
              </div>
              <div className="mock-exam-img">
                <img src={"img/screenshots/mock-exam-sjt.png"} className="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 row about-leaderboard float-right">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <WingBookWhite width={82} />
                <h2>Ace PDG Leaderboard</h2>
                <p>
                  Curious how you rank compared to other Airmen nationwide?
                  Check out the Ace SJT leaderboard once you finish a mock exam.
                </p>
                <p>
                  E-5 and E-6 promotions are awarded based on how you do
                  compared to your peers.
                </p>
                <p>Visit the leaderboard to find out.</p>
                <a
                  href={TRY_APP_URL}
                  target="_blank"
                  className="btn btn-custom btn-custom-white btn-leaderboard mt-4"
                >
                  Claim the top spot now!
                  <i class="fa  fa-arrow-right btn-icon-right"></i>
                </a>
                <div className="spacer" />
              </div>
              <div className="leaderboard-img">
                <img src={"img/screenshots/leaderboard.png"} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid about-images-container">
            <div className="col-lg-6 col-md-6 col-xs-12 row about-mock-exam float-left">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <WingBookDark width={82} />
                <h2>
                  Mock Exam<br></br>Mode
                </h2>
                <p>
                  Think you’ll ace it? Take the closest to actual PFE! 60
                  questions are sourced from AFH1 and 20 from AFH 36-2647 (SJT
                  section). Each question is 1.25 points, totaling 100.
                </p>
                <p>
                  Take the mock exam as often as you need to feel ready. Each
                  exam features new questions!
                </p>
                <a
                  href={TRY_APP_URL}
                  target="_blank"
                  className={`btn btn-custom ${
                    isMobile ? "btn-custom-white" : "btn-custom-light"
                  } btn-mock-exam mt-4`}
                >
                  Give it a try now!
                  <i class="fa  fa-arrow-right btn-icon-right"></i>
                </a>
              </div>
              <div className="mock-exam-img">
                <img src={"img/screenshots/mock-exam.png"} className="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 row about-leaderboard float-right">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <WingBookWhite width={82} />
                <h2>Ace PDG Leaderboard</h2>
                <p>
                  Curious how you rank compared to other Airmen nationwide?
                  Check out the Ace PDG leaderboard once you finish a mock exam.
                </p>
                <p>
                  E-5 and E-6 promotions are awarded based on how you do
                  compared to your peers.
                </p>
                <p>Visit the leaderboard to find out.</p>
                <a
                  href={TRY_APP_URL}
                  target="_blank"
                  className="btn btn-custom btn-custom-white btn-leaderboard mt-4"
                >
                  Give it a try now!
                  <i class="fa  fa-arrow-right btn-icon-right"></i>
                </a>
                <div className="spacer" />
              </div>
              <div className="leaderboard-img">
                <img src={"img/screenshots/leaderboard.png"} />
              </div>
            </div>
          </div>
        )}
      </div>
      {appSelected !== "sjt" && (
        <div id="about">
          <div className="container about-details-container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="about-text">
                  <h5>Why Choose Us?</h5>
                  <p className="about-text-info">
                    Use the Ace PDG mobile app to get promoted to E5 and E6,
                    advance your career, and earn more!
                  </p>
                  <div className="col about-item-container">
                    {props.data
                      ? props.data.Why.map((d, i) => <li>{d}</li>)
                      : "loading"}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 choose-container">
                <img
                  src={"img/choose-image.png"}
                  className="choose-container-image float-right"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {appSelected !== "sjt" && (
        <div id="about-services">
          <div className="container about-services-container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12 choose-container">
                <img
                  src={"img/screenshots/study-made-simple.png"}
                  className="choose-container-image float-left"
                />
              </div>

              <div className="col-lg-6 col-md-6 col-xs-12">
                <div className="about-text">
                  <p className="about-text-info">
                    Ace your E5 or E6 promotion<br></br> with Ace PDG!
                  </p>
                  <div className="col about-item-container">
                    {props.data
                      ? props.data.studyMade.map((d, i) => <li>{d}</li>)
                      : "loading"}
                  </div>
                </div>

                <div className="row store-row">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.acepdgapp&hl=en"
                    target="_blank"
                    alt=""
                  >
                    <AppstoreLogo width={144} />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/ace-pdg/id6738878262"
                    target="_blank"
                    alt=""
                  >
                    <PlaystoreLogo width={144} />
                  </a>
                </div>

                <p className={`text-white text-available`}>
                  Available for iOS 8 and Android Devices From 5.5
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

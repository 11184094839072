import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import { ParallaxProvider } from "react-scroll-parallax";
import { PrivacyPolicy } from "./components/privacy-policy";

import { TermsAndCondition } from "./components/terms-condition";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <Router>
      <div>
        <ParallaxProvider>
          <Toaster />

          <Switch>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>

            <Route path="/terms-and-condition">
              <TermsAndCondition />
            </Route>
            <Route path="/">
              <Root />
            </Route>
          </Switch>
        </ParallaxProvider>
      </div>
    </Router>
  );
};

const Root = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />
    </React.Fragment>
  );
};

export default App;

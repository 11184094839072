import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as AcePdgLogo } from "../assets/img/logo/acepdg-logo.svg";

import { ReactComponent as AcePdgLogoColored } from "../assets/img/logo/acepdg-logo-colored.svg";

import { useLocation } from "react-router-dom";

export const Navigation = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { whiteBg } = props;
  const [isHidden, setIsHidden] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const appSelected = searchParams.get("app") || "pdg";

  const LogoIcon =
    whiteBg || (appSelected === "sjt" && !isMobile)
      ? AcePdgLogoColored
      : AcePdgLogo;

  const hideBar = () => {
    const pos = window.pageYOffset;
    pos > 120 ? setIsHidden(true) : setIsHidden(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", hideBar, { passive: true });

    return () => {
      window.removeEventListener("scroll", hideBar);
    };
  }, []);

  let classHide = isHidden && !whiteBg ? "menu-hide" : "";

  let classBg = whiteBg ? "menu-white" : "";

  let containerClass = appSelected === "sjt" ? "navbar-sjt" : "";

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${classBg} ${classHide}`}
    >
      <div className={`container no-overflow ${containerClass}`}>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar-collapse-home"
          >
            {" "}
            <span className="sr-only"></span> <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="# page-top"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {/* <PDGLogo style={{ height: 48, width: 48 }} /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LogoIcon width={144} />
              {/* Ace PDG
              <span>mobile app</span> */}
            </div>
          </a>{" "}
        </div>

        {isMobile && !whiteBg && (
          <div id="launch-date">
            <p>Available in Google Play and Apple App Store</p>
          </div>
        )}

        <div className="collapse navbar-collapse" id="navbar-collapse-home">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href={`?app=${appSelected}`}
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                Home
              </a>
            </li>
            <li role="presentation">
              <a
                role="presentation"
                id="app-menu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                Apps
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="app-menu">
                <li>
                  <a href="?app=pdg">Ace PDG</a>
                </li>
                <li>
                  <a href="?app=sjt">Ace SJT</a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href={
                  appSelected === "sjt" ? "/?app=sjt#footer" : "/?app=pdg#about"
                }
                className="page-scroll"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                About Us
              </a>
            </li>
            {appSelected !== "sjt" && (
              <li>
                <a
                  href="/#faq"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  PFE FAQs
                </a>
              </li>
            )}

            <li>
              <a
                href={
                  appSelected === "sjt"
                    ? "/?app=sjt#quiz-mode"
                    : "/?app=pdg#about-services"
                }
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                Services
              </a>
            </li>
            {/* <li>
              <a href="#App" className="page-scroll">
                APP
              </a>
            </li> */}

            {appSelected !== "sjt" && (
              <li>
                <a
                  href="/#contact"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  Contact Us
                </a>
              </li>
            )}

            {appSelected !== "sjt" ? (
              <li>
                <button
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/ace-pdg/id6738878262",
                      "_blank"
                    );
                  }}
                  className={`btn btn-custom btn-custom-primary ${
                    isMobile ? "ml-4" : ""
                  }`}
                >
                  Start 3-days Trial
                </button>
              </li>
            ) : (
              <li>
                <button
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/ace-pdg/id6738878262",
                      "_blank"
                    );
                  }}
                  className={`btn btn-custom btn-custom-primary ${
                    isMobile ? "ml-4" : ""
                  }`}
                >
                  Try it Now
                </button>
              </li>
            )}

            {/* {isMobile && (
              <li>
                <div id="launch-date">
                  <p>
                    Available in App Store now, launching in Google Play store
                    soon!
                  </p>
                </div>
              </li>
            )} */}
          </ul>
        </div>
        {!isMobile && !whiteBg && (
          <div id="launch-date">
            <p>Available in Google Play and Apple App Store</p>
          </div>
        )}
      </div>
    </nav>
  );
};

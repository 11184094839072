import React from "react";

import { ReactComponent as AppstoreLogo } from "../assets/img/download/appstore.svg";
import { ReactComponent as PlaystoreLogo } from "../assets/img/download/playstore.svg";

import { ReactComponent as WingBookColored } from "../assets/img/logo/winged-book-colored.svg";

// import { useState } from "react";
// import { useParallax } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";

import { useLocation } from "react-router-dom";

export const Header = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const appSelected = searchParams.get("app") || "pdg";

  // const parallax = useParallax({
  //   onProgressChange: (progress) => {
  //     if (parallax.ref.current) {
  //       // set progress to CSS variable
  //       parallax.ref.current.style.setProperty(
  //         "--progress",
  //         progress.toString()
  //       );
  //     }
  //   },
  // });
  return (
    <header id="home">
      {appSelected === "sjt" ? (
        <div className="sjt-parent">
          <div className="row intro intro-sjt">
            <div className="container sjt-container">
              <div className="col col-md-8 col-sm-12 col-xs-12">
                <WingBookColored width={82} />
                <h2>Mission</h2>

                <div>
                  <p>
                    SJT (Situational Judgment Test) is a behavioral assessment
                    that presents a candidate with a hypothetical scenario and
                    asks them to identify the most and least effective actions.
                    SJTs are used to measure a candidate&#39;s decision-making
                    and thought processes and are a common tool for employers
                    across many industries. The U.S. Air Force includes a 25
                    point SJT section on the Promotion Fitness Exam (PFE).
                    <ul>
                      <li>
                        10 unique scenarios will be tested. You will be asked
                        two questions per scenario: What is the most eﬀective
                        response to the situation? What is the least eﬀective
                        response?
                      </li>
                      <li>
                        The SJT section of the PFE is worth 25/100 points (20
                        questions x 1.25 points).
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col col-md-4 col-md-push-0 col-sm-8 col-sm-push-2 col-xs-12 col-xs-push-2 sjt-mission center-block">
                <img src={"img/screenshots/sjt-mission.png"} />
                <div className="col center-block">
                  <div
                    className="row"
                    style={{
                      justifyContent: "space-evenly",
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "center",
                      maxHeight: 72,
                      marginTop: isMobile ? 0 : 24,
                      marginLeft: isMobile ? -64 : 0,
                      width: isMobile ? "95%" : "100%",
                    }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.acepdgapp&hl=en"
                      target="_blank"
                      alt=""
                    >
                      <AppstoreLogo width={isMobile ? 122 : 144} />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/ace-pdg/id6738878262"
                      target="_blank"
                      alt=""
                    >
                      <PlaystoreLogo width={isMobile ? 122 : 144} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 col-xs-12 intro-text">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  {props.data?.subtitle && (
                    <p className="subtitle">{props.data.subtitle}</p>
                  )}
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <div className="col store-container">
                    <div
                      className="row"
                      style={{
                        justifyContent: "space-evenly",
                        display: "flex",
                        alignItems: "center",
                        maxHeight: 72,
                      }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.acepdgapp&hl=en"
                        target="_blank"
                        alt=""
                      >
                        <AppstoreLogo width={144} />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/ace-pdg/id6738878262"
                        target="_blank"
                        alt=""
                      >
                        <PlaystoreLogo width={144} />
                      </a>
                    </div>

                    {/* <p>Available for iOS 8 and Android Devices From 5.5</p> */}
                  </div>
                </div>
              </div>
              <div className="container-float">
                <img
                  // ref={parallax.ref}
                  src={"img/intro-ss.png"}
                  className="img-float"
                  // style={{
                  //   width: `calc(${isMobile ? 200 : 100}vw * var(--progress))`,
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

import { useState } from "react";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import toast from "react-hot-toast";

import { useLocation } from "react-router-dom";

import Footer from "./footer";

const initialState = {
  name: "",
  custom_email: "",
  message: "",
};

const MAX_BODY_LENGTH = 720;

export const Contact = (props) => {
  const recaptchaRef = React.useRef();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const appSelected = searchParams.get("app") || "pdg";

  const [{ name, custom_email, message }, setState] = useState(initialState);

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const onChangeRecaptcha = (e) => setRecaptchaToken(e);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // FAILED TO GET RECAPTCHA TOKEN
      if (!recaptchaToken) {
        return toast.error(
          "Kindly complete recaptcha verification to proceed."
        );
      }

      const rawResponse = await fetch(
        "https://api.acepdg.org/api/mailer/contact-us",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: custom_email,
            subject: name,
            message: message,
            token: recaptchaToken,
          }),
        }
      );
      const content = await rawResponse.json();

      if (content?.result === true) {
        clearState();
        toast.success("Your submission has been sent!");
        setTimeout(() => {
          window.location?.reload();
        }, 800);
      } else {
        toast.error("Failed to submit form request.");
      }
    } catch (e) {
      return toast.error(
        "Failed to verify recaptcha token, Kindly reload your page."
      );
    }
  };
  return (
    <div>
      {appSelected !== "sjt" && (
        <div id="contact">
          <div className="container center-block text-center">
            <div className="col-md-12">
              <div className="row">
                <div className="section-title text-center">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form
                  className="contact-form"
                  name="sentMessage"
                  validate="true"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="name">Your Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Please enter your name"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="email">Email</label>
                        <input
                          type="email"
                          id="custom_email"
                          name="custom_email"
                          className="form-control"
                          placeholder="Please enter your email"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Your message here"
                      required
                      onChange={handleChange}
                      maxLength={MAX_BODY_LENGTH}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                    <p className="text-muted float-right">
                      Characters left: {MAX_BODY_LENGTH - message?.length}
                    </p>
                  </div>
                  <div id="success"></div>
                  <div className="text-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      onChange={onChangeRecaptcha}
                      onExpired={() => setRecaptchaToken(null)}
                      sitekey="6LeFyngqAAAAAHfQ_cmblT7qwSTuB4Wmf5LgEAZe"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-custom btn-lg float-right"
                  >
                    Submit Message
                  </button>
                </form>
              </div>
            </div>
            {/* <div className="col-md-12 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div> */}
            {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      )}

      {appSelected !== "sjt" && (
        <div id="faq">
          <div className="container">
            <div className="col text-left faq-container">
              <div className="col-lg-3 col-md-3 mt-4 faq-title-container pl-0">
                <h5>FAQs</h5>
                <h1>
                  Common<br></br>Questions
                </h1>
                <span>
                  Find answers to frequently asked questions here to help
                  resolve common issues and learn more about our services.
                </span>
              </div>
              <div className="col-lg-7 col-md-7 col-md-offset-1 faq-container-item float-right pl-0 pr-0">
                <div className="faq-item">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#faq-0"
                    aria-expanded="false"
                    aria-controls="faq-0"
                  >
                    {/* <h4 className="faq-title"> */}
                    What is the Air Force Performance Fitness Exam (PFE)?
                    {/* </h4> */}
                  </a>

                  <div class="collapse" id="faq-0">
                    <p>
                      The Promotion Fitness Examination (PFE) is a test that
                      Airmen take as part of the Weighted Airman Promotion
                      System (WAPS) to determine if they are eligible for
                      promotion to Staff Sergeant (E-5) and Technical Sergeant
                      (E-6).
                    </p>
                  </div>
                </div>

                <div className="faq-item">
                  {/* ---1--- */}
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#faq-1"
                    aria-expanded="false"
                    aria-controls="faq-1"
                  >
                    When can I take the PFE?
                  </a>

                  <div class="collapse" id="faq-1">
                    <p>
                      The 2025 PFE will be available to eligible Airmen on the
                      following dates:
                      <li>
                        Promotion to the rank of Staff Sergeant (E-5): May 1 to
                        June 30, 2025.
                      </li>
                      <li>
                        Promotion to the rank of Technical Sergeant (E-6):
                        February 15 to April 15, 2025
                      </li>
                    </p>
                  </div>
                </div>
                {/* ---2--- */}
                <div className="faq-item">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#faq-2"
                    aria-expanded="false"
                    aria-controls="faq-2"
                    type="button"
                  >
                    What’s on the PFE?
                  </a>

                  <div class="collapse" id="faq-2">
                    <p>
                      The PFE consists of 80 questions, each worth 1.25 points,
                      totaling 100 maximum points.
                      <li>
                        60 questions are based on the study guides released
                        annually by the Air Force. The Staﬀ Sergeant (E-5) and
                        Technical Sergeant (E-6) study guides, commonly referred
                        to as PDG (Professional Development Guide), are entirely
                        derived from AFH-1 (specific content is assessed for
                        each rank).
                      </li>
                      <li>
                        20 questions on the PFE are Situational Judgment Test
                        (SJT) questions and are based on the 24 foundational
                        competencies published in AFH 36-2647.
                      </li>
                    </p>
                  </div>
                </div>

                {/* ---3--- */}
                <div className="faq-item">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#faq-3"
                    aria-expanded="false"
                    aria-controls="faq-3"
                  >
                    What is the Situational Judgment Test (SJT)?
                  </a>

                  <div class="collapse" id="faq-3">
                    <p>
                      An SJT is a behavioral assessment that presents a
                      candidate with a hypothetical work-related scenario and
                      asks how they would respond. SJTs are used to measure a
                      candidate's decision-making and thought processes and are
                      a common tool for employers across many industries.
                      <li>
                        10 unique scenarios will be included in the PFE. You
                        will be asked two questions per scenario: What is the
                        most eﬀective response to the situation? What is the
                        least eﬀective response to the situation?
                      </li>
                      <li>
                        The SJT portion of the PFE is closely linked to the 24
                        Air Force foundational competencies. These competencies
                        can be found in AFH 36-2647.
                      </li>
                    </p>
                  </div>
                </div>

                <div className="faq-item">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#faq-4"
                    aria-expanded="false"
                    aria-controls="faq-4"
                  >
                    How are E-5 and E-6 promotions determined?
                  </a>

                  <div class="collapse" id="faq-4">
                    <p>
                      The Weighted Airman Promotion System (WAPS) is an Air
                      Force program that determines promotions to the ranks of
                      Staﬀ Sergeant (E-5) and Technical Sergeant (E-6). Each
                      year, The Air Force determines the number of promotions
                      needed to fulfill mission requirements and manning goals
                      as set forth by law and regulation. The Air Force then
                      divides these slots by percentages across its various Air
                      Force Specialty Codes (AFSCs), with some career fields
                      receiving a few extra slots. The result is a system in
                      which each candidate for promotion only competes against
                      those in the same Air Force Specialty (AFS). The numbers
                      of promotion slots are not disclosed until after testing.{" "}
                      <br />
                      <br />
                      Eligible candidates receive points based on a number of
                      criteria, including awards and decorations, Enlisted
                      Performance Report (EPR) points, Promotion Fitness
                      Examination (PFE) points, and Specialty Knowledge Test
                      (SKT) points. Fractions of points are awarded for certain
                      categories, resulting in scores that are not whole
                      numbers. Candidates with the highest numbers of points, up
                      to the promotion allowance in each career field, are
                      promoted. <br />
                      <br />
                      The score of the last person promoted is known as the
                      cutoﬀ. The cutoﬀ can gauge how narrowly a given candidate
                      missed a promotion. The cutoﬀ score varies each testing
                      cycle because of variations in both the number of
                      promotion slots and the number of points earned by the top
                      candidates.
                    </p>
                  </div>
                </div>

                <div className="faq-item">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#faq-5"
                    aria-expanded="false"
                    aria-controls="faq-5"
                  >
                    Why should I use Ace PDG over other exam preparation apps?
                  </a>

                  <div class="collapse" id="faq-5">
                    <p>
                      <ol>
                        <li>
                          OUR CONTENT HAS BEEN VETTED BY CURRENTLY ENLISTED
                          AIRMEN!
                        </li>
                        <li>
                          Our co-founder scored 96.25% on the PFE during the
                          2023 testing cycle and was promoted to E6!
                        </li>
                        <li>
                          Ace PDG is the only app to oﬀer a premium Study
                          feature for absolutely FREE!
                          <ul>
                            <li>
                              4,000 flashcards covering every testable sentence
                              of AFH-1 and the 24 foundational competencies in
                              AFH 36-2647, which will prepare you for the SJT
                              portion of the PFE.
                            </li>
                            <li>
                              A digital and audio version of all testable
                              content from AFH-1!
                            </li>
                          </ul>
                        </li>
                        {/* <li>
                        Ace PDG is the only app to offer a premium study feature
                        for absolutely free! A digital and audio version of all
                        testable content from AFH-1 is available with a free
                        download
                      </li> */}
                        <li>
                          We offer a 3 day free trial of our premium features,
                          which includes flash cards in Study mode as well as
                          our Quiz, Mock Exam and Leaderboard features.
                          <ul>
                            <li>
                              4,000 questions covering all testable material.
                              Prepare with flash cards and quizzes that provide
                              instant feedback.
                            </li>

                            <li>
                              Mock Exam mode is the closest to actual PFE there
                              is. 80 timed questions (60 AFH-1/20 SJT).
                            </li>
                            <li>
                              See the Ace Leaderboard to find out how high you
                              score compared to Airmen nationwide!
                            </li>
                          </ul>
                        </li>
                        <li>
                          We give back! Top 3 ranked users (based on mock exam
                          results) at the end of each year will qualify for a
                          digital Amazon.com gift card! Winners will be
                          announced on Instagram.
                          <ul>
                            <li>Gold: $150 gift card</li>
                            <li>Silver: $100 gift card</li>
                            <li>Bronze: $50 gift card</li>
                          </ul>
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center faq-footer">
            Have more questions? <a href="#contact">Ask your questions here</a>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
